import { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import MainContainer from "../Components/MainContainer";
import HomeFooter from "../Components/HomeFooter";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import SliderTile from "../Components/SliderTile";
import QuickLinks from "../Components/QuickLinks";
import MyOrderEmptyState from "../Components/MyOrderEmptyState";
import MyOrderRow from "../Components/MyOrderRow";

import {
  accessTokenAtom,
  isSignedInAtom,
  profileAtom,
  refreshTokenAtom,
  notificationAtom,
  unreadNotificationAtom,
  isGuestAtom,
  currentOrderAtom,
  currentBagAtom,
  orderBagsAtom,
  showBagFormAtom,
  productsAtom,
} from "../Utils/Atoms";
import { clearCurrentOrder } from "../Utils/Helpers";
import OrderBagObj from "../Utils/OrderBagObj";

import { ApiInstance, ApiRoutes } from "../Utils/ApiInstance";
import { API_CLIENT_ID, API_SECRET } from "../Utils/Constants";
import { getProduct } from "../Utils/Products";

function Home() {
  const [signedIn, setSignedIn] = useRecoilState(isSignedInAtom);
  const [profile, setProfile] = useRecoilState(profileAtom);
  const [notifications, setNotifications] = useRecoilState(notificationAtom);
  const [unreadNotifications, setUnreadNotifications] = useRecoilState(
    unreadNotificationAtom
  );
  const [products, setProducts] = useRecoilState(productsAtom);
  const [accessToken, setAccessToken] = useRecoilState(accessTokenAtom);
  const [refreshToken, setRefreshToken] = useRecoilState(refreshTokenAtom);
  const [isGuest, setIsGuest] = useRecoilState(isGuestAtom);

  const [currentOrder, setCurrentOrder] = useRecoilState(currentOrderAtom);
  const [orderBags, setOrderBags] = useRecoilState(orderBagsAtom);
  const [currentBag, setCurrentBag] = useRecoilState(currentBagAtom);
  const [showBagForm, setShowBagForm] = useRecoilState(showBagFormAtom);

  const [username, setUsername] = useState("");
  const [mobile, setMobile] = useState("");
  const [fullName, setFullName] = useState("");
  const [preferredName, setPreferredName] = useState("");
  const [password, setPassword] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [ads, setAds] = useState([]);
  const [orders, setOrders] = useState([]);

  const navigate = useNavigate();

  const getProfile = async () => {
    try {
      const response = await ApiInstance.get(ApiRoutes.GET_PROFILE);
      setProfile(response.data);
    } catch (error) {
      throw error;
    }
  };

  const getNotifications = async () => {
    try {
      const response = await ApiInstance.get(ApiRoutes.GET_NOTIFICATIONS);
      setNotifications(response.data.data);

      // Get number of unread notifications
      let unreadNotifs = response.data.data.filter(
        (notif: any) => notif.read_at == null
      );
      setUnreadNotifications(unreadNotifs.length);
    } catch (error) {
      throw error;
    }
  };

  const getAds = async () => {
    if (signedIn) {
      try {
        const response = await ApiInstance.get(ApiRoutes.GET_ADS);
        setAds(response.data.data);
      } catch (error) {
        throw error;
      }
    } else {
      try {
        const responseToken = await ApiInstance.post(ApiRoutes.GENERATE_TOKEN, {
          client_id: API_CLIENT_ID,
          client_secret: API_SECRET,
          grant_type: "client_credentials",
          scope: "read",
        });

        // save tokens for later
        const { access_token, refresh_token } = responseToken.data;
        setAccessToken(access_token);
        setRefreshToken(refresh_token);

        const response = await ApiInstance.get(ApiRoutes.GET_ADS);
        setAds(response.data.data);
      } catch (error: any) {
        if (error.response?.data?.message != undefined) {
          console.log(error.response.data.message);
        } else {
          console.log(error.message);
        }
      }
    }
  };

  const getProducts = async () => {
    try {
      const response = await ApiInstance.get(ApiRoutes.GET_PRODUCTS);
      setProducts(response.data);
    } catch (error) {
      throw error;
    }
  };

  const getOrders = async () => {
    try {
      const response = await ApiInstance.get(ApiRoutes.GET_ORDERS);
      setOrders(response.data.data);
    } catch (error) {
      throw error;
    }
  };

  const getSliderContent = () => {
    return ads.map((img, index) => <SliderTile img={img} key={index} />);
  };

  const displayOrders = () => {
    return orders
      .slice(0, 5)
      .map((order: any, index: any) => (
        <MyOrderRow order={order} key={index} />
      ));
  };

  // Always getting latest data from API
  useEffect(() => {
    getProfile();
    getNotifications();
    getAds();
    getProducts();
    getOrders();
  }, []);

  const handleNewOrder = () => {
    // setCurrentOrder(new OrderObj());
    setCurrentOrder(clearCurrentOrder(currentOrder));
    setOrderBags([]);
    setCurrentBag(new OrderBagObj());
    setShowBagForm(true);
    navigate("/new-order");
  };

  const handleGuestBook = () => {
    setIsGuest(true);
    setCurrentOrder(clearCurrentOrder(currentOrder));
    setOrderBags([]);
    setCurrentBag(new OrderBagObj());
    setShowBagForm(true);
    navigate("/new-order");
  };

  if (signedIn) {
    return (
      <MainContainer title={"Home"}>
        <div className="x-master">
          <div className="x-master-wrapper">
            <Header />
            {/* <header className="x-header">
              <Carousel showThumbs={false} centerSlidePercentage={30} centerMode selectedItem={1} showStatus={false}
              renderArrowNext={(onClickHandler, hasNext, label) =>
                hasNext && (
                  <div className="x-slider-arrow for-right w-slider-arrow-right" onClick={onClickHandler}>
                    <div className="x-slider-icon w-icon-slider-right" />
                  </div>
                )}
              renderArrowPrev={(onClickHandler, hasPrev, label) =>
                hasPrev && (
                  <div className="x-slider-arrow for-left w-slider-arrow-left" onClick={onClickHandler}>
                    <div className="x-slider-icon w-icon-slider-left" />
                  </div>
              )}
              >
                {getSliderContent()}
              </Carousel >
            </header> */}
            <main className="x-main">
              <h1>Hey {profile.preferred_name || profile.name},</h1>
              <div className="x-actionbar">
                <div className="x-actionbar-block for-mobile-emp">
                  <div className="x-actionbar-group">
                    <div>
                      <h3>Start a New Order</h3>
                      <div className="x-small-text">
                        Build a new laundry order for now and future re-orders!
                      </div>
                    </div>
                  </div>
                  <div className="x-btn-container">
                    <a
                      onClick={handleNewOrder}
                      className="x-btn for-full w-button"
                    >
                      Get Started
                      <span className="x-icon for-btn-inline"></span>
                    </a>
                  </div>
                </div>
                <div className="x-vr-sep for-mob" style={{ display: "none" }} />
                <div className="x-actionbar-block" style={{ display: "none" }}>
                  <div className="x-actionbar-group">
                    <div>
                      <h3>Tokens</h3>
                      <div className="x-small-text">No Expiry Date</div>
                    </div>
                    <div className="x-large-val">
                      {profile.total_tokens || 0}
                    </div>
                  </div>
                  <div className="x-btn-container">
                    <a href="/top-up" className="x-btn for-full w-button">
                      Top Up<span className="x-icon for-btn-inline"></span>
                    </a>
                  </div>
                </div>
                {/* <div className="x-vr-sep" /> */}
                {/* <div className="x-actionbar-block">
                  <div className="x-actionbar-group">
                    <div>
                      <h3>Scan QR</h3>
                      <div className="x-small-text">Start a Washer or Dryer</div>
                    </div>
                  </div>
                  <div className="x-btn-container">
                    <a href="/qr-scan" className="x-btn for-full w-button">
                      Scan<span className="x-icon for-btn-inline"></span>
                    </a>
                  </div>
                </div> */}
              </div>
              <h2>Your Orders</h2>
              {orders.length === 0 ? (
                <MyOrderEmptyState />
              ) : (
                <div className="x-orderbar">
                  <ol role="list" className="x-orderbar-list w-list-unstyled">
                    {displayOrders()}
                  </ol>
                  <div className="x-btn-container">
                    <a
                      href="/orders"
                      className="x-sml-btn for-secondary w-button"
                    >
                      Sell All orders
                      <span className="x-icon for-btn-inline"></span>
                    </a>
                  </div>
                </div>
              )}
              <h2>Quick Links</h2>
              <QuickLinks signedIn />
            </main>
            <HomeFooter />
          </div>
          <Footer />
        </div>
      </MainContainer>
    );
  }

  return (
    <MainContainer title={"Home"}>
      <div className="x-master">
        <div className="x-master-wrapper">
          <Header signedOut />
          {/* <header className="x-header">
            <Carousel showThumbs={false} centerSlidePercentage={30} centerMode selectedItem={1} showStatus={false}
            renderArrowNext={(onClickHandler, hasNext, label) =>
              hasNext && (
                <div className="x-slider-arrow for-right w-slider-arrow-right" onClick={onClickHandler}>
                  <div className="x-slider-icon w-icon-slider-right" />
                </div>
              )}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
              hasPrev && (
                <div className="x-slider-arrow for-left w-slider-arrow-left" onClick={onClickHandler}>
                  <div className="x-slider-icon w-icon-slider-left" />
                </div>
            )}
            >
              {getSliderContent()}
            </Carousel >
          </header> */}
          <main className="x-main">
            <div className="x-actionbar">
              <div className="x-actionbar-block">
                <div className="x-actionbar-group">
                  <div>
                    <h3>Login to Book</h3>
                    <div className="x-small-text">
                      Order, re-order with your own account
                    </div>
                  </div>
                </div>
                <div className="x-btn-container">
                  <a href="/sign-in" className="x-btn for-full w-button">
                    Login<span className="x-icon for-btn-inline"></span>
                  </a>
                </div>
              </div>
              <div className="x-vr-sep" />
              <div className="x-actionbar-block">
                <div className="x-actionbar-group">
                  <div>
                    <h3>Sign up</h3>
                    <div className="x-small-text">
                      Register for a new account
                    </div>
                  </div>
                </div>
                <div className="x-btn-container">
                  <a href="/sign-up" className="x-btn for-full w-button">
                    Sign Up<span className="x-icon for-btn-inline"></span>
                  </a>
                </div>
              </div>
              <div className="x-vr-sep for-mob" />
              <div className="x-actionbar-block for-mobile-emp">
                <div className="x-actionbar-group">
                  <div>
                    <h3>Book as a Guest</h3>
                    <div className="x-small-text">
                      Place your order without logging in
                    </div>
                  </div>
                </div>
                <div className="x-btn-container">
                  <a
                    onClick={() => handleGuestBook()}
                    className="x-btn for-full w-button"
                  >
                    Book Now<span className="x-icon for-btn-inline"></span>
                  </a>
                </div>
              </div>
            </div>
            <h2>Your Orders</h2>
            <div className="x-empty-placholder">
              <img
                src="images/62.svg"
                loading="eager"
                alt=""
                className="x-empty-placeholder-img"
              />
              <div>You're not logged in, no orders to show.</div>
            </div>
            <h2>Quick Links</h2>
            <QuickLinks />
          </main>
          <HomeFooter />
        </div>
        <Footer />
      </div>
    </MainContainer>
  );
}

export default Home;
