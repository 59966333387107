import { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import { setKey } from "react-geocode";
import moment from "moment";

import MainContainer from "../Components/MainContainer";
import Header from "../Components/Header";
import HomeFooter from "../Components/HomeFooter";
import Footer from "../Components/Footer";
import NewOrderFooter from "../Components/NewOrderFooter";
import NewOrderStepper from "../Components/NewOrderStepper";
import AddressSelectModals from "../Components/Modals/AddresSelectModals";
import ErrorModal from "../Components/Modals/ErrorModal";
// import CityList from '../Components/CityList';

import OrderObj from "../Utils/OrderObj";
import {
  currentOrderAtom,
  profileAtom,
  isGuestAtom,
  shippingMethodsAtom,
} from "../Utils/Atoms";
import { GOOGLE_MAPS_API_KEY } from "../Utils/Constants";
import { ApiInstance, ApiRoutes } from "../Utils/ApiInstance";

// Form validation
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, bool } from "yup";
import { RadioButtonOption } from "../Components/Forms/RadioButtonOption";
import { TextField } from "../Components/Forms/TextField";
import { getStartTime, isNextDayDelivery } from "../Utils/Dates";
import { getShippingMethod, isExpressShipping } from "../Utils/Helpers";

interface UserProfile {
  mobile_number: string;
  country_code: string;
  pickup_delivery_same?: boolean;
  pickup_building_type?: string;
  pickup_address_1?: string;
  pickup_address_2?: string;
  pickup_city?: string;
  pickup_postcode?: string;
  pickup_state?: string;
  opHour?: string;
  is_default_pickup_address?: boolean;
  delivery_building_type?: string;
  delivery_address_1?: string;
  delivery_address_2?: string;
  delivery_city?: string;
  delivery_postcode?: string;
  delivery_state?: string;
  opHour_2?: string;
  is_default_delivery_address?: boolean;
}

function AddressSelect() {
  setKey(GOOGLE_MAPS_API_KEY);

  const [currentOrder, setCurrentOrder] = useRecoilState(currentOrderAtom);
  const [profile, setProfile] = useRecoilState(profileAtom);
  const [isGuest, setIsGuest] = useRecoilState(isGuestAtom);
  const [shippingMethods, setShippingMethods] =
    useRecoilState(shippingMethodsAtom);

  const [isSubmitting, setIsSubmitting] = useState(false);
  // const [shippingMethods, setShippingMethods] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalCode, setModalCode] = useState(0);
  // const [noCoverage, setNoCoverage] = useState(false)
  const [canProceed, setCanProceed] = useState(false);
  const [haveError, setHaveError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorTitle, setErrorTitle] = useState("");

  const schema = object({}).shape({
    customer_name: string().required("Please enter customer name"),
    customer_phone: string().required("Please provide a contact phone number"),
    customer_email: string()
      .email()
      .required("Please provide a valid contact email address"),
    pickup_building_type: string().required(
      "Please choose the type of building"
    ),
    pickup_address_1: string().required("Please provide your complete address"),
    pickup_address_2: string().optional(),
    pickup_city: string().required("Please fill in your city"),
    pickup_postcode: string().required("Please provide a valid postcode"),
    pickup_state: string().required("Please fill in your state"),

    pickup_delivery_same: bool().optional(),

    // these fields are all optional UNLESS pickup_delivery_same is true
    delivery_building_type: string().when("pickup_delivery_same", {
      is: false,
      then: (schema) => schema.required("Please choose the type of building"),
      otherwise: (schema) => schema.optional(),
    }),
    delivery_address_1: string().when("pickup_delivery_same", {
      is: false,
      then: (schema) => schema.required("Please provide your complete address"),
      otherwise: (schema) => schema.optional(),
    }),
    delivery_address_2: string().optional(),
    delivery_city: string().when("pickup_delivery_same", {
      is: false,
      then: (schema) => schema.required("Please fill in your city"),
      otherwise: (schema) => schema.optional(),
    }),
    delivery_postcode: string().when("pickup_delivery_same", {
      is: false,
      then: (schema) => schema.required("Please provide a valid postcode"),
      otherwise: (schema) => schema.optional(),
    }),
    delivery_state: string().when("pickup_delivery_same", {
      is: false,
      then: (schema) => schema.required("Please fill in your state"),
      otherwise: (schema) => schema.optional(),
    }),

    is_default_pickup_address: bool().optional(),
    is_default_delivery_address: bool().optional(),
    pickup_opHour: string().when("pickup_building_type", {
      is: "hotel" || "office",
      then: (schema) =>
        schema.required("Please fill in operational hours of office/hotel"),
      otherwise: (schema) => schema.optional(),
    }),
    delivery_opHour: string().when("delivery_building_type", {
      is: "hotel" || "office",
      then: (schema) =>
        schema.required("Please fill in operational hours of office/hotel"),
      otherwise: (schema) => schema.optional(),
    }),
  });

  const {
    register,
    trigger,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      customer_name: currentOrder.customer_name,
      customer_phone: currentOrder.customer_phone,
      customer_email: currentOrder.customer_email,

      pickup_building_type: currentOrder.pickup_building_type,
      pickup_address_1: currentOrder.pickup_address_1,
      pickup_address_2: currentOrder.pickup_address_2,
      pickup_city: currentOrder.pickup_city,
      pickup_postcode: currentOrder.pickup_postcode,
      pickup_state: currentOrder.pickup_state,

      delivery_building_type: currentOrder.delivery_building_type,
      delivery_address_1: currentOrder.delivery_address_1,
      delivery_address_2: currentOrder.delivery_address_2,
      delivery_city: currentOrder.delivery_city,
      delivery_postcode: currentOrder.delivery_postcode,
      delivery_state: currentOrder.delivery_state,

      pickup_delivery_same: currentOrder.pickup_delivery_same,
      is_default_pickup_address: currentOrder.is_default_pickup_address,
      is_default_delivery_address: currentOrder.is_default_delivery_address,
      pickup_opHour: currentOrder.pickup_opHour,
      delivery_opHour: currentOrder.delivery_opHour,
    },
  });

  const nav = useNavigate();

  // Always getting latest data from API
  useEffect(() => {
    handleGetShippingMethod();
    prefillAddress();
  }, []);

  const handleGetShippingMethod = async () => {
    const data = await getShippingMethod();
    setShippingMethods(data);
  };

  const prefillAddress = () => {
    let updated = Object.assign(new OrderObj(), currentOrder);

    if (
      updated.pickup_building_type &&
      updated.pickup_address_1 &&
      updated.pickup_address_2 &&
      updated.pickup_state &&
      updated.pickup_city &&
      updated.pickup_postcode
    ) {
      return;
    }

    if (
      updated.delivery_building_type &&
      updated.delivery_address_1 &&
      updated.delivery_address_2 &&
      updated.delivery_state &&
      updated.delivery_city &&
      updated.delivery_postcode
    ) {
      return;
    }

    if (profile.is_default_pickup_address === true) {
      updated.pickup_building_type = profile.address_building_type;
      updated.pickup_address_1 = profile.address;
      updated.pickup_address_2 = profile.address_line_2;
      updated.pickup_city = profile.city;
      updated.pickup_postcode = profile.postcode;
      updated.pickup_state = profile.state;
      updated.is_default_pickup_address = true;
      updated.pickup_delivery_same = profile.pickup_delivery_same;
      updated.pickup_opHour = profile.opHour;
    } else {
      updated.pickup_building_type = "";
      updated.pickup_address_1 = "";
      updated.pickup_address_2 = "";
      updated.pickup_city = "";
      updated.pickup_postcode = "";
      updated.pickup_state = "";
      updated.is_default_pickup_address = false;
      updated.pickup_delivery_same = true;
      updated.pickup_opHour = "";
    }

    if (profile.is_default_delivery_address === true) {
      updated.delivery_building_type = profile.address_building_type;
      updated.delivery_address_1 = profile.address;
      updated.delivery_address_2 = profile.address_line_2;
      updated.delivery_city = profile.city;
      updated.delivery_postcode = profile.postcode;
      updated.delivery_state = profile.state;
      updated.is_default_delivery_address = true;
      updated.delivery_opHour = profile.opHour_2;
    } else {
      updated.delivery_building_type = "";
      updated.delivery_address_1 = "";
      updated.delivery_address_2 = "";
      updated.delivery_city = "";
      updated.delivery_postcode = "";
      updated.delivery_state = "";
      updated.is_default_delivery_address = false;
      updated.delivery_opHour = "";
    }

    setCurrentOrder(updated);
  };

  // MODAL
  const handleOpenModal = (e: any, modalNumber: number) => {
    e.preventDefault();
    setShowModal(true);
    setModalCode(modalNumber);
  };

  const handleCloseModal = (e: any) => {
    e.preventDefault();
    setShowModal(false);
    setModalCode(0);
    // Reset error modal
    setHaveError(false);
    setErrorMessage("");
    setErrorTitle("");
  };

  const generateModalContent = () => {
    if (haveError)
      return (
        <ErrorModal
          title={errorTitle.length !== 0 ? errorTitle : null}
          description={errorMessage}
        />
      );
    return <AddressSelectModals modalCode={modalCode} />;
  };

  // SHIPPING METHOD
  const generateShippingMethods = () => {
    // let updated = Object.assign(new OrderObj(), currentOrder);
    let list = [...shippingMethods].sort((a: any, b: any) => a.order - b.order);

    return list.map((sm: any, index: any) => {
      if (sm.status == "active") {
        const disabled = false;
        // isNextDayDelivery(getStartTime(currentOrder.pickup_schedule_slot)) &&
        // isExpressShipping(sm.id, shippingMethods); // TODO
        // const disabled = updated.pickup_building_type.toLowerCase().includes('land') && sm.name.toLowerCase().includes('door')

        const onClick = disabled
          ? undefined
          : () => {
              changeShippingMethod(sm.id, sm.amount, sm.name, sm.type);
            };

        return (
          <li
            key={index}
            className={`x-stack-picker-item ${
              currentOrder.shipping_method_id == sm.id ? "for-active" : ""
            } ${disabled ? "disabled" : ""}`}
          >
            <div className="x-flex" onClick={onClick}>
              <img
                alt=""
                loading="lazy"
                src={sm.normal_image}
                className="x-stack-thumb"
              />
              <div>
                <div className="x-emp-text">{sm.name}</div>
                <div className="x-small-text for-no-margin">
                  <span className="x-red-emp for-price">{`RM${sm.amount.toFixed(
                    2
                  )}`}</span>
                  {sm.description}
                </div>
              </div>
            </div>
            <label className="x-picker-toggle w-radio">
              <input
                type="radio"
                id="shipping_method_id"
                value={sm.id}
                onChange={handleChange}
                checked={currentOrder.shipping_method_id == 1}
                name="ShippingMethod"
                data-name="ShippingMethod"
                className="w-form-formradioinput x-picker-btn w-radio-input"
              />
              <span className="x-picker-label w-form-label">Radio</span>
            </label>
            <a className={`x-stack-picker-btn`} onClick={onClick}>
              Select
            </a>
          </li>
        );
      }
    });
  };

  const changeShippingMethod = (id: any, amount: any, name: any, type: any) => {
    let updated = Object.assign(new OrderObj(), currentOrder);
    updated["shipping_method_id"] = id;
    updated["shipping_method_amount"] = amount;
    updated["shipping_method_name"] = name;

    let pickupDate = updated.pickup_schedule_date;
    // Logic express or standard
    // TODO
    if (isExpressShipping(id, shippingMethods)) {
      updated["delivery_schedule_date"] = moment(pickupDate).format("YYMMDD");
    } else {
      updated["delivery_schedule_date"] = moment(pickupDate)
        .add(2, "days")
        .format("YYMMDD");
    }
    setCurrentOrder(updated);
  };

  // FORM
  const handleChange = (e: any): void => {
    let updated = Object.assign(new OrderObj(), currentOrder);
    let target = e.target.id;
    let newValue = e.target.value;
    updated[target] = newValue;

    setCurrentOrder(updated);

    // Reset when address is changed
    // setCanProceed(false)
    // setNoCoverage(false)
  };

  const handleSaveAsDefaultPickupAddress = (e: any): void => {
    // let updated = {
    //   ...profile
    // }
    let updated = Object.assign(new OrderObj(), currentOrder);
    let newValue = e.target.value === "false" ? true : false;
    updated.is_default_pickup_address = newValue;
    setValue("is_default_pickup_address", newValue);
    // setProfile(updated)
    setCurrentOrder(updated);
  };

  const handleSaveAsDefaultDeliveryAddress = (e: any): void => {
    let updated = Object.assign(new OrderObj(), currentOrder);
    let newValue = e.target.value === "false" ? true : false;
    updated.is_default_delivery_address = newValue;
    setValue("is_default_delivery_address", newValue);
    setCurrentOrder(updated);
  };

  const handlePickupDeliverySame = (e: any): void => {
    let updated = Object.assign(new OrderObj(), currentOrder);
    let newValue = e.target.value === "false" ? true : false;
    updated.pickup_delivery_same = newValue;
    setValue("pickup_delivery_same", newValue);

    if (!newValue) {
      // Clearing fields for delivery
      updated.delivery_building_type = "";
      updated.delivery_address_1 = "";
      updated.delivery_address_2 = "";
      updated.delivery_city = "";
      updated.delivery_postcode = "";
      updated.delivery_state = "";
      updated.delivery_opHour = "";
      updated.is_default_delivery_address = false;
    }
    // Setting same delivery address as pickup
    // updated.delivery_building_type = currentOrder.pickup_building_type
    // updated.delivery_address_1 = currentOrder.pickup_address_1
    // updated.delivery_address_2 = currentOrder.pickup_address_2
    // updated.delivery_city = currentOrder.pickup_city
    // updated.delivery_postcode = currentOrder.pickup_postcode
    // updated.delivery_state = currentOrder.pickup_state

    setCurrentOrder(updated);
  };

  const onSubmit = async (e: any): Promise<any> => {
    e.preventDefault();

    let newCurrentOrder = {
      ...currentOrder,
    };

    if (newCurrentOrder.pickup_delivery_same) {
      // Setting same delivery address as pickup
      newCurrentOrder.delivery_building_type =
        currentOrder.pickup_building_type;
      newCurrentOrder.delivery_address_1 = currentOrder.pickup_address_1;
      newCurrentOrder.delivery_address_2 = currentOrder.pickup_address_2;
      newCurrentOrder.delivery_city = currentOrder.pickup_city;
      newCurrentOrder.delivery_postcode = currentOrder.pickup_postcode;
      newCurrentOrder.delivery_state = currentOrder.pickup_state;
      newCurrentOrder.delivery_opHour = currentOrder.pickup_opHour;
      newCurrentOrder.is_default_delivery_address =
        currentOrder.is_default_pickup_address;
    }
    setCurrentOrder(newCurrentOrder);

    // Validation Pickup Details
    if (
      newCurrentOrder.pickup_building_type === null ||
      newCurrentOrder.pickup_building_type.length === 0
    ) {
      setHaveError(true);
      setErrorMessage("Please select a pickup building type");
      setShowModal(true);
      return;
    }
    if (
      newCurrentOrder.pickup_address_1 === null ||
      newCurrentOrder.pickup_address_1.length === 0
    ) {
      setHaveError(true);
      setErrorMessage("Please add a pickup address line 1");
      setShowModal(true);
      return;
    }
    if (
      newCurrentOrder.pickup_address_2 === null ||
      newCurrentOrder.pickup_address_2.length === 0
    ) {
      setHaveError(true);
      setErrorMessage("Please add a pickup address line 2");
      setShowModal(true);
      return;
    }
    if (
      newCurrentOrder.pickup_city === null ||
      newCurrentOrder.pickup_city.length === 0
    ) {
      setHaveError(true);
      setErrorMessage("Please add a pickup city");
      setShowModal(true);
      return;
    }
    if (
      newCurrentOrder.pickup_postcode === null ||
      newCurrentOrder.pickup_postcode.length === 0
    ) {
      setHaveError(true);
      setErrorMessage("Please add a pickup postcode");
      setShowModal(true);
      return;
    }
    if (
      newCurrentOrder.pickup_state === null ||
      newCurrentOrder.pickup_state.length === 0
    ) {
      setHaveError(true);
      setErrorMessage("Please add a pickup state");
      setShowModal(true);
      return;
    }
    if (
      (newCurrentOrder.pickup_building_type == "hotel" ||
        newCurrentOrder.pickup_building_type == "office") &&
      (newCurrentOrder.pickup_opHour == null ||
        newCurrentOrder.pickup_opHour.length === 0)
    ) {
      setHaveError(true);
      setErrorMessage("Operational hours of office/hotel required");
      setShowModal(true);
      return;
    }

    // Validation Delivery Details
    if (
      newCurrentOrder.delivery_building_type === null ||
      newCurrentOrder.delivery_building_type.length === 0
    ) {
      setHaveError(true);
      setErrorMessage("Please select a delivery building type");
      setShowModal(true);
      return;
    }
    if (
      newCurrentOrder.delivery_address_1 === null ||
      newCurrentOrder.delivery_address_1.length === 0
    ) {
      setHaveError(true);
      setErrorMessage("Please add a delivery address line 1");
      setShowModal(true);
      return;
    }
    if (
      newCurrentOrder.delivery_address_2 === null ||
      newCurrentOrder.delivery_address_2.length === 0
    ) {
      setHaveError(true);
      setErrorMessage("Please add a delivery address line 2");
      setShowModal(true);
      return;
    }
    if (
      newCurrentOrder.delivery_city === null ||
      newCurrentOrder.delivery_city.length === 0
    ) {
      setHaveError(true);
      setErrorMessage("Please add a delivery city");
      setShowModal(true);
      return;
    }
    if (
      newCurrentOrder.delivery_postcode === null ||
      newCurrentOrder.delivery_postcode.length === 0
    ) {
      setHaveError(true);
      setErrorMessage("Please add a delivery postcode");
      setShowModal(true);
      return;
    }
    if (
      newCurrentOrder.delivery_state === null ||
      newCurrentOrder.delivery_state.length === 0
    ) {
      setHaveError(true);
      setErrorMessage("Please add a delivery state");
      setShowModal(true);
      return;
    }
    if (
      (newCurrentOrder.delivery_building_type == "hotel" ||
        newCurrentOrder.delivery_building_type == "office") &&
      (newCurrentOrder.delivery_opHour == null ||
        newCurrentOrder.delivery_opHour.length === 0)
    ) {
      setHaveError(true);
      setErrorMessage("Operational hours of office/hotel required");
      setShowModal(true);
      return;
    }

    // Validation Shipping Details
    if (newCurrentOrder.shipping_method_id === 0) {
      setHaveError(true);
      setErrorMessage("Please select a shipping method");
      setShowModal(true);
      return;
    }

    // if (canProceed) {
    if (
      newCurrentOrder.is_default_pickup_address ||
      newCurrentOrder.is_default_delivery_address
    )
      return await updateProfile(newCurrentOrder);
    else return nav("/add-ons");
    // }

    // const checkPickUp = fromAddress(`${currentOrder.pickup_address_1}, ${currentOrder.pickup_address_2}, ${currentOrder.pickup_city}, ${currentOrder.pickup_postcode}, ${currentOrder.pickup_state}`)
    // // const checkDelivery = fromAddress(`${currentOrder.delivery_address_1}, ${currentOrder.delivery_address_2}, ${currentOrder.delivery_city}, ${currentOrder.delivery_postcode}, ${currentOrder.delivery_state}`)

    // Promise.all([checkPickUp,
    //   // checkDelivery
    // ])
    //   .then((resp) => {
    //     const pickUpLat = resp[0].results[0].geometry.location.lat
    //     const pickUpLng = resp[0].results[0].geometry.location.lng
    //     // const deliveryLat = resp[1].results[0].geometry.location.lat
    //     // const deliveryLng = resp[1].results[0].geometry.location.lng
    //     const checkPickUpCoverage = ApiInstance.get(ApiRoutes.GET_COVERAGE,{
    //       params: {
    //         lat: pickUpLat,
    //         lng: pickUpLng
    //       }
    //     })
    //     // const checkDeliveryCoverage = ApiInstance.get(ApiRoutes.GET_COVERAGE,{
    //     //   params: {
    //     //     lat: deliveryLat,
    //     //     lng: deliveryLng
    //     //   }
    //     // })

    //     return Promise.all([checkPickUpCoverage
    //       // , checkDeliveryCoverage
    //     ])
    //       .then((resp) => {
    //         let pickUpCoverage = resp[0].data
    //         // let deliveryCoverage = resp[1].data

    //         if (!pickUpCoverage.is_within_coverage
    //           //  || !deliveryCoverage.is_within_coverage
    //            ) {
    //           setNoCoverage(true)
    //           setCanProceed(false)

    //           // Error modal
    //           setHaveError(true)
    //           // setErrorMessage('Sorry no coverage for pickup / delivery')
    //           setErrorMessage('Sorry no coverage for pickup & Delivery')
    //           setShowModal(true)
    //           if (!pickUpCoverage.is_within_coverage) {
    //             setErrorMessage('Sorry no coverage for pickup & Delivery address')
    //           }
    //           // else if (!deliveryCoverage.is_within_coverage) {
    //           //   setErrorMessage('Sorry no coverage for delivery address')
    //           // }
    //         } else {
    //           // Showing modal can proceed
    //           setHaveError(true)
    //           setErrorTitle('All set!')
    //           // setErrorMessage('Coverage available for pickup and delivery addresses. Click Order Now to continue.')
    //           setErrorMessage('Coverage available for pickup & Delivery address. Click Order Now to continue.')
    //           setShowModal(true)
    //           setCanProceed(true)
    //         }
    //       }).catch((err) => {
    //         // log that I have an error, return the entire array;
    //     })
    //   }).catch((err) => {
    //     setHaveError(true)
    //     setErrorMessage('Cannot check coverage')
    //     setShowModal(true)
    //     // log that I have an error, return the entire array;
    //   })
  };

  const updateProfile = async (newCurrentOrder: any) => {
    let updatedProfile: UserProfile = {
      mobile_number: profile.phone.number,
      country_code: profile.phone.country_code,
      pickup_delivery_same: newCurrentOrder.pickup_delivery_same,
    };

    if (newCurrentOrder.is_default_pickup_address) {
      updatedProfile = {
        ...updatedProfile,
        pickup_building_type: newCurrentOrder.pickup_building_type,
        pickup_address_1: newCurrentOrder.pickup_address_1,
        pickup_address_2: newCurrentOrder.pickup_address_2,
        pickup_city: newCurrentOrder.pickup_city,
        pickup_postcode: newCurrentOrder.pickup_postcode,
        pickup_state: newCurrentOrder.pickup_state,
        opHour: newCurrentOrder.pickup_opHour,
        is_default_pickup_address: newCurrentOrder.is_default_pickup_address,
      };
    }
    if (newCurrentOrder.is_default_delivery_address) {
      updatedProfile = {
        ...updatedProfile,
        delivery_building_type: newCurrentOrder.delivery_building_type,
        delivery_address_1: newCurrentOrder.delivery_address_1,
        delivery_address_2: newCurrentOrder.delivery_address_2,
        delivery_city: newCurrentOrder.delivery_city,
        delivery_postcode: newCurrentOrder.delivery_postcode,
        delivery_state: newCurrentOrder.delivery_state,
        opHour_2: newCurrentOrder.delivery_opHour,
        is_default_delivery_address:
          newCurrentOrder.is_default_delivery_address,
      };
    }

    try {
      const response = await ApiInstance.put(
        ApiRoutes.UPDATE_PROFILE,
        updatedProfile
      );

      const updated = {
        ...profile,
        pickup_delivery_same: newCurrentOrder.pickup_delivery_same,
      };
      if (newCurrentOrder.is_default_pickup_address) {
        updated.address_building_type = newCurrentOrder.pickup_building_type;
        updated.address = newCurrentOrder.pickup_address_1;
        updated.address_line_2 = newCurrentOrder.pickup_address_2;
        updated.city = newCurrentOrder.pickup_city;
        updated.postcode = newCurrentOrder.pickup_postcode;
        updated.state = newCurrentOrder.pickup_state;
        updated.opHour = newCurrentOrder.pickup_opHour;
        updated.is_default_pickup_address =
          newCurrentOrder.is_default_pickup_address;
      }
      if (newCurrentOrder.is_default_delivery_address) {
        updated.address_2_building_type =
          newCurrentOrder.delivery_building_type;
        updated.address_2_line_1 = newCurrentOrder.delivery_address_1;
        updated.address_2_line_2 = newCurrentOrder.delivery_address_2;
        updated.city_2 = newCurrentOrder.delivery_city;
        updated.postcode_2 = newCurrentOrder.delivery_postcode;
        updated.state_2 = newCurrentOrder.delivery_state;
        updated.opHour_2 = newCurrentOrder.delivery_opHour;
        updated.is_default_delivery_address =
          newCurrentOrder.is_default_delivery_address;
      }
      setProfile(updated);
      // console.log("Profile updated", response.data)
      return nav("/add-ons");
    } catch (error: any) {
      if (error.response?.data?.message != undefined) {
        // console.log(error.response.data.message);
        setHaveError(true);
        setErrorMessage(error.response.data.message);
        setShowModal(true);
      } else {
        setHaveError(true);
        setErrorMessage(error.message);
        setShowModal(true);
      }
      // setCanProceed(false);
      return;
    }
  };

  return (
    <MainContainer title={"Select Address"}>
      <div className="x-master">
        <div className="x-master-wrapper">
          <Header />
          <header className="x-header for-subpage">
            <h1>Book Laundry Service</h1>
          </header>
          <main className="x-main for-subpage">
            <div className="x-order-header">
              <div className="x-text-flex">
                <a
                  href="/schedule"
                  className="x-icon-btn for-secondary is-back w-button"
                >
                  
                </a>
                <h2>Address</h2>
                <a
                  data-toggle="dialog-page-guide"
                  data-tippy-content="How this works?"
                  onClick={(e) => handleOpenModal(e, 1)}
                  className="x-helper-btn"
                >
                  
                </a>
              </div>
              <NewOrderStepper step={3} />
            </div>
            <div className="x-order-wrapper">
              <div className="x-form-wrapper w-form">
                <form
                  id="wf-form-Order-Address-Form"
                  name="wf-form-Order-Address-Form"
                  data-name="Order Address Form"
                  method="get"
                  data-wf-page-id="64fc18206b4ef83f4ee28174"
                  data-wf-element-id="6ca47c44-3a43-0c2f-1bbd-1aa46a68960f"
                >
                  <div className="x-form-body">
                    <div className="x-address-manager">
                      <div
                        data-address="pickup"
                        className="x-address-manager-row"
                      >
                        <div className="x-bag-item-header">
                          <h3>Pickup Address</h3>
                        </div>
                        <div className="x-helper-block for-green">
                          <div className="x-icon for-btn-inline is-green">
                            
                          </div>
                          <div>
                            <div className="x-emp-text">
                              Make sure you are in Klang Valley
                            </div>
                            <div>
                              dobiQueen currently supports pickup and delivery
                              services in Kuala Lumpur and Selangor areas.
                            </div>
                          </div>
                        </div>
                        <div className="x-form-container">
                          <div className="x-form-row">
                            <div className="x-form-col">
                              <label htmlFor="Display-Name">
                                Building Type
                                <span className="is-required">*</span>
                              </label>
                              <div className="x-input-toggle-block">
                                <RadioButtonOption
                                  id="pickup_building_type"
                                  value={"highrise"}
                                  onChange={handleChange}
                                  checked={
                                    currentOrder.pickup_building_type ==
                                    "highrise"
                                  }
                                  name="PickUpBuildingType"
                                  label="High-rise"
                                />
                                <RadioButtonOption
                                  id="pickup_building_type"
                                  value={"landed"}
                                  onChange={handleChange}
                                  checked={
                                    currentOrder.pickup_building_type ==
                                    "landed"
                                  }
                                  name="PickUpBuildingType"
                                  label="Landed"
                                />
                                <RadioButtonOption
                                  id="pickup_building_type"
                                  value={"hotel"}
                                  onChange={handleChange}
                                  checked={
                                    currentOrder.pickup_building_type == "hotel"
                                  }
                                  name="PickUpBuildingType"
                                  label="Hotel"
                                />
                                <RadioButtonOption
                                  id="pickup_building_type"
                                  value={"office"}
                                  onChange={handleChange}
                                  checked={
                                    currentOrder.pickup_building_type ==
                                    "office"
                                  }
                                  name="PickUpBuildingType"
                                  label="Office"
                                />
                              </div>
                            </div>
                          </div>
                          {(currentOrder.pickup_building_type == "hotel" ||
                            currentOrder.pickup_building_type == "office") && (
                            <div className="x-form-row">
                              <TextField
                                id="pickup_opHour"
                                name="pickup_opHour"
                                label="Operational Hours of Office/Hotel"
                                value={currentOrder.pickup_opHour}
                                onChange={handleChange}
                                placeholder="Let us know if there are operational hours"
                                required={true}
                              />
                            </div>
                          )}
                          <div className="x-form-row">
                            <TextField
                              id="pickup_address_1"
                              name="PickUpAddress1"
                              label="Address Line 1"
                              value={currentOrder.pickup_address_1}
                              onChange={handleChange}
                              required={true}
                            />
                          </div>
                          <div className="x-form-row">
                            <TextField
                              id="pickup_address_2"
                              name="PickUpAddress2"
                              label="Address Line 2"
                              value={currentOrder.pickup_address_2}
                              onChange={handleChange}
                              required={true}
                            />
                          </div>
                          <div className="x-form-row">
                            <div className="x-form-col for-med">
                              <label htmlFor="State">
                                State<span className="is-required">*</span>
                              </label>
                              <div className="x-input-toggle-block">
                                <RadioButtonOption
                                  id="pickup_state"
                                  value={"Kuala Lumpur"}
                                  onChange={handleChange}
                                  checked={
                                    currentOrder.pickup_state == "Kuala Lumpur"
                                  }
                                  name="PickupState"
                                  label="Kuala Lumpur"
                                />
                                <RadioButtonOption
                                  id="pickup_state"
                                  value={"Selangor"}
                                  onChange={handleChange}
                                  checked={
                                    currentOrder.pickup_state == "Selangor"
                                  }
                                  name="PickupState"
                                  label="Selangor"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="x-form-row">
                            <TextField
                              containerClassName="for-med"
                              id="pickup_city"
                              name="PickUpCity"
                              label="City"
                              value={currentOrder.pickup_city}
                              onChange={handleChange}
                              required={true}
                            />
                            <TextField
                              containerClassName="for-med"
                              id="pickup_postcode"
                              name="PickUpPostcode"
                              label="Postcode"
                              value={currentOrder.pickup_postcode}
                              onChange={handleChange}
                              required={true}
                            />
                          </div>
                          {!isGuest && (
                            <div className="x-form-row">
                              <div className="x-form-opt">
                                <label className="w-checkbox x-input-chkbox">
                                  <div
                                    className={`w-checkbox-input w-checkbox-input--inputType-custom x-input-chkbox-btn ${
                                      currentOrder.is_default_pickup_address
                                        ? "w--redirected-checked"
                                        : ""
                                    }`}
                                  />
                                  <input
                                    type="checkbox"
                                    id="is_default_pickup_address"
                                    name="is_default_pickup_address"
                                    data-name="is_default_pickup_address"
                                    style={{
                                      opacity: 0,
                                      position: "absolute",
                                      zIndex: -1,
                                    }}
                                    value={currentOrder.is_default_pickup_address?.toString()}
                                    onChange={handleSaveAsDefaultPickupAddress}
                                  />
                                  <span className="x-input-chkbox-label w-form-label">
                                    Save this as my default pickup address.
                                  </span>
                                </label>
                              </div>
                            </div>
                          )}
                          <div className="x-form-row">
                            <div className="x-form-opt">
                              <label className="w-checkbox x-input-chkbox">
                                <div
                                  className={`w-checkbox-input w-checkbox-input--inputType-custom x-input-chkbox-btn ${
                                    currentOrder.pickup_delivery_same
                                      ? "w--redirected-checked"
                                      : ""
                                  }`}
                                />
                                <input
                                  type="checkbox"
                                  id="pickup_delivery_same"
                                  name="pickup_delivery_same"
                                  data-name="pickup_delivery_same"
                                  style={{
                                    opacity: 0,
                                    position: "absolute",
                                    zIndex: -1,
                                  }}
                                  value={currentOrder.pickup_delivery_same?.toString()}
                                  onChange={handlePickupDeliverySame}
                                />
                                <span className="x-input-chkbox-label w-form-label">
                                  Deliver back to the same address as above.
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      {!currentOrder.pickup_delivery_same && (
                        <div
                          data-address="return"
                          className="x-address-manager-row"
                        >
                          <div className="x-bag-item-header">
                            <h3>Delivery Address</h3>
                          </div>
                          <div className="x-form-container">
                            <div className="x-form-row">
                              <div className="x-form-col">
                                <label htmlFor="Display-Name">
                                  Building Type
                                  <span className="is-required">*</span>
                                </label>
                                <div className="x-input-toggle-block">
                                  <RadioButtonOption
                                    id="delivery_building_type"
                                    value={"highrise"}
                                    onChange={handleChange}
                                    checked={
                                      currentOrder.delivery_building_type ==
                                      "highrise"
                                    }
                                    name="DeliveryBuildingType"
                                    label="High-rise"
                                  />
                                  <RadioButtonOption
                                    id="delivery_building_type"
                                    value={"landed"}
                                    onChange={handleChange}
                                    checked={
                                      currentOrder.delivery_building_type ==
                                      "landed"
                                    }
                                    name="DeliveryBuildingType"
                                    label="Landed"
                                  />
                                  <RadioButtonOption
                                    id="delivery_building_type"
                                    value={"hotel"}
                                    onChange={handleChange}
                                    checked={
                                      currentOrder.delivery_building_type ==
                                      "hotel"
                                    }
                                    name="DeliveryBuildingType"
                                    label="Hotel"
                                  />
                                  <RadioButtonOption
                                    id="delivery_building_type"
                                    value={"office"}
                                    onChange={handleChange}
                                    checked={
                                      currentOrder.delivery_building_type ==
                                      "office"
                                    }
                                    name="DeliveryBuildingType"
                                    label="Office"
                                  />
                                </div>
                              </div>
                            </div>
                            {(currentOrder.delivery_building_type == "hotel" ||
                              currentOrder.delivery_building_type ==
                                "office") && (
                              <div className="x-form-row">
                                <TextField
                                  id="delivery_opHour"
                                  name="delivery_opHour"
                                  label="Operational Hours of Office/Hotel"
                                  value={currentOrder.delivery_opHour}
                                  onChange={handleChange}
                                  placeholder="Let us know if there are operational hours"
                                  required={true}
                                />
                              </div>
                            )}
                            <div className="x-form-row">
                              <TextField
                                id="delivery_address_1"
                                name="DeliveryAddress1"
                                label="Address Line 1"
                                value={currentOrder.delivery_address_1}
                                onChange={handleChange}
                                required={true}
                              />
                            </div>
                            <div className="x-form-row">
                              <TextField
                                id="delivery_address_2"
                                name="DeliveryAddress2"
                                label="Address Line 2"
                                value={currentOrder.delivery_address_2}
                                onChange={handleChange}
                                required={true}
                              />
                            </div>
                            <div className="x-form-row">
                              <div className="x-form-col for-med">
                                <label htmlFor="State-2">
                                  State<span className="is-required">*</span>
                                </label>
                                <div className="x-input-toggle-block">
                                  <RadioButtonOption
                                    id="delivery_state"
                                    value={"Kuala Lumpur"}
                                    onChange={handleChange}
                                    checked={
                                      currentOrder.delivery_state ==
                                      "Kuala Lumpur"
                                    }
                                    name="DeliveryState"
                                    label="Kuala Lumpur"
                                  />
                                  <RadioButtonOption
                                    id="delivery_state"
                                    value={"Selangor"}
                                    onChange={handleChange}
                                    checked={
                                      currentOrder.delivery_state == "Selangor"
                                    }
                                    name="DeliveryState"
                                    label="Selangor"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="x-form-row">
                              <TextField
                                containerClassName="for-med"
                                id="delivery_city"
                                name="DeliveryCity"
                                label="City"
                                value={currentOrder.delivery_city}
                                onChange={handleChange}
                                required={true}
                              />
                              <TextField
                                containerClassName="for-med"
                                id="delivery_postcode"
                                name="DeliveryPostcode"
                                label="Postcode"
                                value={currentOrder.delivery_postcode}
                                onChange={handleChange}
                                required={true}
                              />
                            </div>
                            {!isGuest && (
                              <div className="x-form-row">
                                <div className="x-form-opt">
                                  <label className="w-checkbox x-input-chkbox">
                                    <div
                                      className={`w-checkbox-input w-checkbox-input--inputType-custom x-input-chkbox-btn ${
                                        currentOrder.is_default_delivery_address
                                          ? "w--redirected-checked"
                                          : ""
                                      }`}
                                    />
                                    <input
                                      type="checkbox"
                                      id="is_default_delivery_address"
                                      name="is_default_delivery_address"
                                      data-name="is_default_delivery_address"
                                      style={{
                                        opacity: 0,
                                        position: "absolute",
                                        zIndex: -1,
                                      }}
                                      value={currentOrder.is_default_delivery_address?.toString()}
                                      onChange={
                                        handleSaveAsDefaultDeliveryAddress
                                      }
                                    />
                                    <span className="x-input-chkbox-label w-form-label">
                                      Save this as my default delivery address.
                                    </span>
                                  </label>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                      <div
                        data-address="pickup"
                        className="x-address-manager-row"
                      >
                        <div className="x-bag-item-header">
                          <h3>Shipping Method</h3>
                        </div>
                        <div className="x-form-container">
                          <div className="x-workspace-picker-block">
                            <ol
                              role="list"
                              className="x-stack-picker-list w-list-unstyled"
                            >
                              {generateShippingMethods()}
                            </ol>
                          </div>
                        </div>
                      </div>
                    </div>
                    <NewOrderFooter
                      goToNext={(e: any) => onSubmit(e)}
                      // buttonText={canProceed ? 'Order Now' : 'Check Coverage'}
                      buttonText="Order Now"
                    />
                  </div>
                </form>
              </div>
            </div>
          </main>
          <HomeFooter />
        </div>
        <Footer />
      </div>

      {/* MODAL SECTION */}
      <div
        data-toggletarget="dialog-page-guide"
        className={`x-dialog ${showModal ? "" : "for-hide"}`}
      >
        <div className="x-dialog-wrapper">
          <div
            className={`x-dialog-form w-form ${showModal ? "" : "for-hide"}`}
          >
            <form
              id="wf-form-Guide-Dialog-Form"
              name="wf-form-Guide-Dialog-Form"
              data-name="Guide Dialog Form"
              method="get"
              data-wf-page-id="64fc18206b4ef83f4ee28174"
              data-wf-element-id="cfb25b3e-6032-c0c4-861a-dc1b5db263d9"
            >
              <div className="x-dialog-header">
                <div className="x-dialog-title for-action-icon"></div>
                <div className="x-btn-container">
                  <a
                    onClick={(e) => handleCloseModal(e)}
                    className="x-icon-btn for-simple is-close-modal w-button"
                  >
                    
                  </a>
                </div>
              </div>
              <div className="x-dialog-body">
                <div className="x-form-wrapper">
                  <div className="x-form-row">{generateModalContent()}</div>
                  <div className="x-form-action">
                    <div className="x-btn-container">
                      {canProceed ? (
                        <a
                          onClick={(e: any) => onSubmit(e)}
                          className="x-btn w-button"
                        >
                          Order Now
                        </a>
                      ) : (
                        <a
                          onClick={(e) => handleCloseModal(e)}
                          className="x-btn for-secondary is-close-modal w-button"
                        >
                          Dismiss
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="x-dialog-overlay" />
        </div>
      </div>
    </MainContainer>
  );
}

export default AddressSelect;
