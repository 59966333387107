import { useState, useEffect } from 'react'

const MainContainer = (props) => {
  const [visible, setVisible] = useState('hidden');

  // This will run one time after the component mounts
  useEffect(() => {
    const onPageLoad = () => {
      setVisible('visible');
    };
    // Check if the page has already loaded
    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);
  
  return (
      <div style={{ visibility: visible }}> 
        <title>{props.title}</title>
        <link href="/css/normalize.css" rel="stylesheet" type="text/css" />
        <link href="/css/webflow.css" rel="stylesheet" type="text/css" />
        <link href="/css/dq-webapp.webflow.css" rel="stylesheet" type="text/css" />
        <link href="https://fonts.googleapis.com" rel="preconnect" />
        <link href="https://fonts.gstatic.com" rel="preconnect" crossOrigin="anonymous" />
        <link href="/images/favicon.ico" rel="shortcut icon" type="image/x-icon" />
        <link href="/images/webclip.png" rel="apple-touch-icon" />
        {/*  REQUIRED SELECT2 CSS  */}
        <link
          href="https://cdnjs.cloudflare.com/ajax/libs/select2/4.0.13/css/select2.min.css"
          integrity="sha512-nMNlpuaDPrqlEls3IX/Q56H36qvBASwb3ipuo3MxeWbsQB1881ox0cRv7UPTgBlriqoynt35KjEwgGUeUXIPnw=="
          crossOrigin="anonymous"
          referrerPolicy="no-referrer"
          rel="stylesheet"/>
        {/*  REQUIRED INTERNATIONAL NUMBER CSS  */}
        <link
          href="https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/css/intlTelInput.css"
          rel="stylesheet" />
        {/*  REQUIRED PLUGIN CSS  */}
        <link
          rel="stylesheet"
          // href="../css/plugin.css" />
          href="https://dl.dropboxusercontent.com/scl/fi/wq9247j5yf28nxocldvn6/dq-plugin.css?rlkey=77o69ixvdigulvgljcs0pfycj" />
        {/*  REQUIRED CUSTOM CSS  */}
        <link
          rel="stylesheet"
          // href="../css/custom.css" />
          href="https://dl.dropboxusercontent.com/scl/fi/hlin6wommr76fd4weni8f/dq-custom.css?rlkey=gdurv6vixr60ae6jk1adh841h" />
        <div className="x-access-body">
          {props.children}
        </div>
      </div>
    )
}

export default MainContainer