import { getRecoil, setRecoil } from 'recoil-nexus';
import axios from 'axios';

import { API_BASE_URL, API_CLIENT_ID, API_SECRET } from './Constants';
import ApiRoutes from '../Routes/ApiRoutes';
import { accessTokenAtom, isSignedInAtom, refreshTokenAtom } from '../Utils/Atoms';

const ApiInstance = axios.create({
  baseURL: API_BASE_URL
});

// Request interceptor for API calls
ApiInstance.interceptors.request.use(
  async config => {
    const accessToken = getRecoil(accessTokenAtom);
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
      config.headers.Accept = 'application/json';
      config.headers['Content-Type'] = 'application/json';
    }
    return config;
  },
  error => {
    Promise.reject(error)
  }
);

// Response interceptor for API calls
ApiInstance.interceptors.response.use((response) => {
  return response
}, async function (error) {
  const originalRequest = error.config;
  if (error.response.status === 403 && !originalRequest._retry) {
    originalRequest._retry = true;
    const accessToken = await refreshAccessToken();
    axios.defaults.headers.Authorization = 'Bearer ' + accessToken;
    return ApiInstance(originalRequest);
  }
  return Promise.reject(error);
});

// Refresh Access Token
const refreshAccessToken = async () => {
  const refreshToken = getRecoil(refreshTokenAtom);
  const accessToken = getRecoil(accessTokenAtom);

  const response = await ApiInstance.post(ApiRoutes.GENERATE_TOKEN, {
    grant_type: 'refresh_token',
    refresh_token: refreshToken,
    client_id: API_CLIENT_ID,
    client_secret: API_SECRET,
    scope: '*',
  });

  if (response.status === 200) {
    setRecoil(accessToken, response.data.access_token);
    setRecoil(refreshToken, response.data.refresh_token);
    return response.data.access_token;
  }
}


export {ApiInstance, ApiRoutes};